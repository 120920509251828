export const RESERVED_BY_TEXT_INPUT = new Set([
  'Ctrl + a',
  'Ctrl + c',
  'Ctrl + v',
  'Ctrl + x',
  'Ctrl + z',
  'Ctrl + y',
  'Ctrl + p',
  'Enter',
  'Shift + Enter',
  'Ctrl + Backspace',
  'Ctrl + Delete',
  'Home',
  'Ctrl + Home',
  'Ctrl + Shift + Home',
  'End',
  'Ctrl + End',
  'Ctrl + Shift + End',
  'PageUp',
  'PageDown',
  'Shift + PageUp',
  'Shift + PageDown',
  'ArrowLeft',
  'Ctrl + ArrowLeft',
  'Shift + ArrowLeft',
  'Ctrl + Shift + ArrowLeft',
  'ArrowRight',
  'Ctrl + ArrowRight',
  'Shift + ArrowRight',
  'Ctrl + Shift + ArrowRight',
  'ArrowUp',
  'Shift + ArrowUp',
  'ArrowDown',
  'Shift + ArrowDown'
])
